import I18N from '@/i18n';
import { useCallback, useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import bowser from 'bowser';
import _ from 'lodash';
import styles from './index.less';
import colors from '@/style/color.less';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { openOfficialSiteByAppWindow } from '@/utils/pageUtils';
import {
  addEventListener,
  getAppBuildNumber,
  isElectron,
  sendAsync,
  sendSync,
} from '@/utils/ElectronUtils';
import constants from '@/constants';
import ColoursIcon from '@/components/Common/ColoursIcon';
import DMConfirm from '@/components/Common/DMConfirm';
import { metaAppVersionConfigsGet } from '@/services/api-MetaAPI/MetaController';
import { useDelayAutoInstallModal } from '@/components/UserCenter/components/DelayAutoInstallModal';
import { useModel } from '@@/plugin-model/useModel';
import { getSysPresSettings } from '@/i18n/I18N';
import { useCustomServiceModal } from '@/components/CustomService/CustomServiceModal';
import useAppCheck from '@/hooks/useAppCheck';

export const AutoInstallCountDown: React.FC<{ duration: number; appPlatformCode?: string }> = (
  props,
) => {
  const { appPlatformCode } = props;
  const [v, setV] = useState(props.duration);
  const timer = useRef<any>(0);

  const showDelayAutoInstallModal = useDelayAutoInstallModal();

  useEffect(() => {
    timer.current = setInterval(() => {
      setV(v - 1);
      if (v === 1) {
        clearInterval(timer.current);
        message.destroy('auto-install-alert');
      }
    }, 1000);
    return () => {
      clearInterval(timer.current);
    };
  }, [v]);

  const handleDelayBtnClick = useCallback(() => {
    showDelayAutoInstallModal({
      appPlatformCode,
      onFinish: () => {
        clearInterval(timer.current);
        message.destroy('auto-install-alert');
      },
    });
  }, [appPlatformCode, showDelayAutoInstallModal]);

  return (
    <span>
      {I18N.t('版本升级提示：花漾客户端有最新版本，即将为您自动升级（')}
      {v}）
      <a style={{ marginLeft: 16 }} onClick={handleDelayBtnClick}>
        {I18N.t('暂不升级')}
      </a>
    </span>
  );
};

export function getVersionConfig(
  versionConfigs?: API.AppPlatformVersionConfig[],
  appPlatform?: string,
) {
  const browserParsed = bowser.getParser(window.navigator.userAgent);
  const osName = browserParsed.getOSName(true);
  const config = _.find(versionConfigs, (c) => {
    if (osName === 'windows') {
      if (appPlatform === 'Win7') {
        return c.platfrom === 'windows7';
      }
      const version = parseInt(browserParsed.getOSVersion().replace('NT ', ''), 10);
      if (version < 10) {
        return c.platfrom === 'windows7';
      }
      return c.platfrom === 'windows';
    }
    return c.platfrom === osName;
  });
  return config;
}

/**
 * 在线客服
 * @constructor
 */
const CustomService = () => {
  const { initialState } = useModel('@@initialState');
  const [isUpdaterDownloaded, setUpdaterDownloaded] = useState(false);
  const [hasNewVersion, setHasNewVersion] = useState(false);
  const [newCurrentBuildNo, setNewCurrentBuildNo] = useState(0);

  const appBuildNumber = getAppBuildNumber();
  const showCustomServiceModal = useCustomServiceModal();
  const { showNewVersionToInstallConfirm } = useAppCheck();

  useEffect(() => {
    if (!isElectron()) return;
    const checkState = (state: string) => {
      if (state === 'downloaded') {
        setUpdaterDownloaded(true);
      }
    };
    addEventListener('app-updater-info', (evt, data) => {
      checkState(data.state);
    });
    addEventListener('app-will-start-install', (evt, data) => {
      // 弹出提示
      message.info({
        key: 'auto-install-alert',
        content: (
          <AutoInstallCountDown
            duration={data.seconds}
            appPlatformCode={initialState?.appPlatformCode}
          />
        ),

        duration: 0,
        className: styles.autoInstallTips,
      });
    });
    sendAsync('get-app-updater-info').then((data) => {
      checkState(data.state);
    });
  }, []);

  useEffect(() => {
    if (!isElectron()) return () => {};
    const sysPresSettings = getSysPresSettings();
    let versionCheckTimer: any = 0;
    if (!sysPresSettings.autoUpdate) {
      // 提醒是否有新版本
      versionCheckTimer = setInterval(async () => {
        const { data: versionConfigsData = {} } = await metaAppVersionConfigsGet();
        const { versionConfigs } = versionConfigsData;
        if (versionConfigs) {
          const config = getVersionConfig(versionConfigs, initialState?.appPlatformCode);
          if (config) {
            // const minBuildNo = config.minBuildNo!;
            const currentBuildNo = config.currentBuildNo!;
            if (
              appBuildNumber &&
              Number(appBuildNumber) < Number(currentBuildNo) &&
              newCurrentBuildNo !== currentBuildNo
            ) {
              setHasNewVersion(true);
              setNewCurrentBuildNo(currentBuildNo);
            }
          }
        }
      }, 2 * 3600 * 1000);
    }
    return () => {
      clearInterval(versionCheckTimer);
    };
  }, [appBuildNumber, initialState?.appPlatformCode, newCurrentBuildNo]);

  useEffect(() => {
    if (isUpdaterDownloaded) {
      const sessionIds = sendSync('get-opening-session-ids', {});
      if (sessionIds.length > 0) {
        console.info('有活跃会话，不自动安装更新');
        return;
      }
      sendAsync('show-main-window');
      showNewVersionToInstallConfirm(10);
    }
  }, [isUpdaterDownloaded, showNewVersionToInstallConfirm]);

  if (isUpdaterDownloaded) {
    return (
      <a
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          DMConfirm({
            iconType: 'info',
            title: I18N.t('已经为您准备了最新版本'),
            content: I18N.t('最新版本已经就绪，请点击“立即重启”为您更新（原有数据将完整保留）'),

            okText: I18N.t('立即重启'),
            okButtonProps: { danger: true },
            cancelText: I18N.t('下次再说'),
            onOk: async () => {
              const sessionIds = sendSync('get-opening-session-ids', {});
              if (sessionIds.length > 0) {
                setTimeout(() => {
                  sendAsync('quit-and-install-updater');
                }, 5e3);
                return sendAsync('close-all-browsers').then(() => {
                  sendAsync('quit-and-install-updater');
                });
              }
              return sendAsync('quit-and-install-updater');
            },
          });
        }}
      >
        <ColoursIcon className="NEW_24" style={{ margin: '-5px auto' }} size={25} />
        <span className="menu-item-name" style={{ color: colors.errorColor }}>
          {I18N.t('升级')}
        </span>
      </a>
    );
  }

  if (hasNewVersion) {
    return (
      <a
        className="menu-item"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          DMConfirm({
            iconType: 'info',
            title: I18N.t('发现有新版本可供升级'),
            content: I18N.t('强烈建议您立即下载最新版本，重新安装（无需卸载）即可无缝升级'),
            okText: I18N.t('立即下载'),
            cancelText: I18N.t('下次再说'),
            onOk: () => {
              openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp);
            },
            onCancel: () => {
              setHasNewVersion(false);
            },
          });
        }}
      >
        <ColoursIcon className="NEW_24" style={{ margin: '-4px auto' }} size={22} />
        <span className="menu-item-name" style={{ color: colors.errorColor }}>
          {I18N.t('新版本')}
        </span>
      </a>
    );
  }

  return (
    <a className="menu-item" onClick={showCustomServiceModal}>
      <IconFontIcon iconName="kefu_24" />
      <span className="menu-item-name">{I18N.t('支持')}</span>
    </a>
  );
};

export default CustomService;
