import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DMModal from '@/components/Common/Modal/DMModal';
import I18N from '@/i18n/I18N';
import ProTable from '@ant-design/pro-table';
import { Button, message, Space, Typography } from 'antd';
import { useRequest } from 'umi';
import { teamJoinListGet } from '@/services/api-Account/TeamController';
import { scrollProTableOptionFn } from '@/mixins/table';
import constants from '@/constants';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { isElectron, sendAsync, sendSync } from '@/utils/ElectronUtils';
import { getTeamIdFromUrl, setJwt } from '@/utils/utils';
import { accountLogoutGet } from '@/services/api-Account/LoginController';
import { clearCurrentUser } from '@/hooks/useCurrentUser';
import { history } from '@@/core/history';
import request from '@/websocket/request';
import pMinDelay from 'p-min-delay';
import { redirectToHome } from '@/utils/pageUtils';

const TeamStatusEnum = {
  Deleted: '已删除',
  Pending: '未就绪',
  Ready: '已就绪',
  Blocked: '已禁用',
};
const TeamTypeEnum = {
  normal: '普通',
  partner: '伙伴',
  tk: 'TK',
  gh: 'TK公会',
  crs: 'CRS',
};

const OptionButton = (props: { team: API.TeamWithRoleVo; loading: boolean; finished: boolean }) => {
  const { team, loading, finished } = props;
  const { roleCode } = team;
  const icon = useMemo(() => {
    if (loading) {
      return (
        <Typography.Link>
          <IconFontIcon iconName={'loading_24'} spin />
        </Typography.Link>
      );
    }
    if (finished) {
      return (
        <Typography.Text type={'success'}>
          <IconFontIcon iconName={'Check-Circle_24'} />
        </Typography.Text>
      );
    }
    return false;
  }, [loading, finished]);
  const text = useMemo(() => {
    const addonAfter = roleCode === 'superadmin' ? I18N.t('解散团队') : I18N.t('退出团队');
    // 团队拥有者
    if (loading) {
      return (
        <Typography.Link>
          {I18N.t('正在{{action}}', {
            action: addonAfter,
          })}
        </Typography.Link>
      );
    }
    if (finished) {
      return (
        <Typography.Text type={'success'}>
          {I18N.t('{{action}}成功', {
            action: addonAfter,
          })}
        </Typography.Text>
      );
    }
    return (
      <Typography.Text
        style={{ cursor: 'pointer' }}
        type={roleCode === 'superadmin' ? 'danger' : 'warning'}
      >
        {addonAfter}
      </Typography.Text>
    );
  }, [loading, roleCode, finished]);
  return (
    <Space align={'center'}>
      {icon}
      {text}
    </Space>
  );
};

export async function logoutAndCloseBrowsers() {
  try {
    // 检测是否有打开的分身会话
    if (isElectron()) {
      const closeAllBrowsers = async () => {
        try {
          await sendAsync('close-all-browsers');
        } catch (e) {
          console.error(e);
        }
      };
      let teamId;
      try {
        teamId = getTeamIdFromUrl();
      } catch (e) {}
      if (teamId) {
        // 已经进入某个团队
        const sessionIds = sendSync('get-opening-session-ids', { teamId });
        if (sessionIds.length > 0) {
          await closeAllBrowsers();
        }
      } else {
        // 选择团队页
        await closeAllBrowsers();
      }
    }
    await accountLogoutGet({
      ignoreTeamId: true,
    });
    clearCurrentUser();
    setJwt('');
  } catch (e) {
    console.error(e);
  }
  clearCurrentUser();
  setJwt('');
  history.push(constants.loginPath);
}
const CancelAccount = () => {
  const [visible, setVisible] = useState(true);
  const [time, setTime] = useState(9);
  const [optionStatus, setOptionStatus] = useState<{ [id: string]: boolean }>({});
  const { data: teams, loading } = useRequest(
    () => {
      return teamJoinListGet({
        status: 'Ready',
      });
    },
    {
      initialData: [],
      formatResult(res) {
        return res.data!;
      },
    },
  );

  const timer = useRef<any>();
  useEffect(() => {
    if (teams) {
      timer.current = setInterval(() => {
        setTime((prevState) => {
          if (prevState >= 1) {
            return prevState - 1;
          }
          clearInterval(timer.current);
          return prevState;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [teams]);
  const updateStatus = useCallback(
    (index: number) => {
      return pMinDelay(
        new Promise<void>((resolve) => {
          setOptionStatus((prevState) => {
            return {
              ...prevState,
              [teams[index].id!]: true,
            };
          });
          resolve();
        }),
        (index + 1) * (3000 / teams.length),
      );
    },
    [teams],
  );

  const { run: submit, loading: submitting } = useRequest(
    async () => {
      // 检测是否有打开的分身会话
      if (isElectron()) {
        const closeAllBrowsers = async () => {
          try {
            await sendAsync('close-all-browsers');
          } catch (e) {
            console.error(e);
          }
        };
        let teamId;
        try {
          teamId = getTeamIdFromUrl();
        } catch (e) {}
        if (teamId) {
          // 已经进入某个团队
          const sessionIds = sendSync('get-opening-session-ids', { teamId });
          if (sessionIds.length > 0) {
            await closeAllBrowsers();
          }
        } else {
          // 选择团队页
          await closeAllBrowsers();
        }
      }
      for (let i = 0; i < teams?.length; i++) {
        await updateStatus(i);
      }
      await request(`/api/account/destroy`, {
        params: {
          confirm: true,
        },
        method: 'DELETE',
      });
      message.success(
        I18N.t('{{action}}成功', {
          action: I18N.t('账号销毁'),
        }),
      );
      await accountLogoutGet({
        ignoreTeamId: true,
      });
      clearCurrentUser();
      setJwt('');
      history.push(constants.loginPath);
    },
    {
      manual: true,
    },
  );
  const footer = useMemo(() => {
    return (
      <>
        <Button type={'primary'} disabled={time > 0} onClick={submit} loading={submitting}>
          {time === 0 ? I18N.t('确定') : I18N.t('确定') + `（${time}s）`}
        </Button>
        <Button
          onClick={() => {
            setVisible(false);
          }}
        >
          {I18N.t('取消')}
        </Button>
      </>
    );
  }, [time, submit]);
  return (
    <DMModal
      width={640}
      footer={footer}
      visible={visible}
      title={I18N.t('账号注销')}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div>您在花漾中拥有以下团队资源，如需注销，将进行以下清理操作，请确认：</div>
      <div style={{ height: 300, marginTop: 16 }}>
        <ProTable<API.TeamWithRoleVo>
          {...scrollProTableOptionFn({
            pagination: false,
          })}
          dataSource={teams}
          loading={loading}
          columns={[
            {
              title: '团队名称',
              dataIndex: 'name',
              ellipsis: true,
            },
            // {
            //   title: '团队类型',
            //   dataIndex: 'teamType',
            //   valueEnum: TeamTypeEnum,
            //   ellipsis: true,
            // },
            {
              title: '角色',
              dataIndex: 'roleCode',
              renderText(_text, record) {
                return constants.Role[record.roleCode!];
              },
              ellipsis: true,
            },
            {
              title: '操作',
              valueType: 'option',
              render(_text, record) {
                return (
                  <OptionButton
                    team={record}
                    loading={submitting}
                    finished={optionStatus[record.id!]}
                  />
                );
              },
            },
          ]}
        />
      </div>
    </DMModal>
  );
};
export default CancelAccount;
