import I18N from '@/i18n';
import DMConfirm, { DMLoading } from '@/components/Common/DMConfirm';
import pMinDelay from 'p-min-delay';
import { teamQuotaDetailGet } from '@/services/api-Account/TeamController';
import { Button, Checkbox, Form, message, Radio, Space, Switch, Typography } from 'antd';
import { shopPoliciesSetSecurityPolicyPut } from '@/services/api-ShopAPI/ShopPoliciesController';
import type { ReactNode } from 'react';
import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import HelpLink from '@/components/HelpLink';
import { showAlert, useAuthJudgeCallback } from '@/components/Common/FunctionButton';
import Functions from '@/constants/Functions';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { getProductSiteLink, getTeamIdFromUrl, isMacPlatform } from '@/utils/utils';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { openByDefaultBrowser, openOfficialSiteByAppWindow } from '@/utils/pageUtils';
import constants from '@/constants';
import Constants from '@/constants';
import EventEmitter from 'events';
import DmSwitch from '@/components/Switch';
import { getAppBuildNumber, isElectron, sendAsync } from '@/utils/ElectronUtils';
import { metaAppVersionConfigsGet } from '@/services/api-MetaAPI/MetaController';
import { getVersionConfig } from '@/components/CustomService';

export function useBeforeEnabledSecurityPolicy() {
  const hasAuth = useAuthJudgeCallback();
  return useCallback(
    (shopIds: number[]) => {
      return new Promise<void>((resolve, reject) => {
        if (!hasAuth(Functions.SHOP_AUTHORIZE)) {
          showAlert();
          reject(new Error('NO AUTH'));
          return;
        }
        const loading = DMLoading({
          title: I18N.t('正在查询安全策略配额设置'),
        });
        pMinDelay(
          teamQuotaDetailGet({
            quotaName: 'ShopSecurityPolicy',
          }),
          700,
        )
          .then((res) => {
            const { data } = res;
            loading.destroy();
            const text = data?.needPay ? (
              <>
                {shopIds?.length > 1 ? I18N.t('所选分身') : I18N.t('当前分身')}
                <Typography.Text type={'danger'}>
                  {I18N.t('开启安全策略将产生费用')}
                </Typography.Text>
                {I18N.t('（{{price}}花瓣每分身每日），但可随时关闭，关闭后将不再产生费用', {
                  price: data?.price,
                })}
              </>
            ) : (
              I18N.t('当前分身开启安全策略免费')
            );

            DMConfirm({
              width: 600,
              iconType: 'info',
              title: I18N.t('确认要开启本分身的安全策略吗？'),
              content: (
                <Space direction={'vertical'}>
                  <span>
                    {I18N.t(
                      '1.安全策略是指可以通过事前预防、事中监管、事后审计等维度，将该分身纳入安全沙箱托管',
                    )}
                  </span>
                  <span>
                    {I18N.t(
                      '2.当分身所维护的账号信息高度敏感时，强烈建议您开启安全策略，这样可以确保日常运营的安全可控',
                    )}
                  </span>
                  <span>
                    3.&nbsp;{text}
                    <HelpLink style={{ marginLeft: 12 }} href={'/account/security'} />
                  </span>
                </Space>
              ),

              onOk() {
                shopPoliciesSetSecurityPolicyPut({
                  shopIds,
                  enabled: true,
                })
                  .then(() => {
                    resolve();
                    message.success(
                      I18N.t('{{module}}{{action}}成功', {
                        module: I18N.t('安全策略'),
                        action: I18N.t('开启'),
                      }),
                    );
                  })
                  .catch(reject);
              },
              onCancel() {
                reject(new Error(I18N.t('取消开启安全策略') as unknown as string));
              },
            });
          })
          .catch((e) => {
            loading.destroy();
            DMConfirm({
              type: 'info',
              iconType: 'warn',
              title: e?.message || I18N.t('安全策略配额获取失败'),
              content: I18N.t('请稍后重试'),
            });
            reject(new Error(I18N.t('取消开启安全策略') as unknown as string));
          });
      });
    },
    [hasAuth],
  );
}
export function useCtrlMode(extra: React.ReactNode = null) {
  const [active, setActive] = useState(false);
  const ctrlFn = useCallback((e) => {
    setActive(e.ctrlKey || e.metaKey);
  }, []);
  useEffect(() => {
    document.addEventListener('keydown', ctrlFn);
    document.addEventListener('keyup', ctrlFn);
    return () => {
      document.removeEventListener('keydown', ctrlFn);
      document.removeEventListener('keyup', ctrlFn);
    };
  }, []);
  const dom = useMemo(() => {
    return (
      <div
        style={{
          display: 'flex',
          padding: '4px 8px 0 8px',
          borderTop: '1px solid #d9d9d9',
          alignItems: 'center',
          height: 36,
          justifyContent: 'space-between',
          fontSize: 12,
          gap: 4,
          color: '#888',
        }}
      >
        <Space size={4}>
          <IconFontIcon iconName={'info_24'} size={'inherit'} />
          <span>
            {I18N.t('按住{{key}}可多选', {
              key: isMacPlatform() ? '⌘' : 'Ctrl',
            })}
          </span>
        </Space>
        {extra}
      </div>
    );
  }, [extra]);

  return {
    dom,
    active,
  };
}
export async function beforeBindIp(ipInfo: API.IpDetailVo) {
  const { dynamic, sticky } = ipInfo;
  const ref = createRef<{ submit: () => void }>();
  if (!dynamic) {
    return {};
  }
  return new Promise<{
    dynamicStrategy?: 'Off' | 'Remain' | 'SwitchOnSession';
    disableWebrtc?: boolean;
    disableLongLatitude?: boolean;
  }>((resolve) => {
    const dialog = DMConfirm({
      width: 540,
      title: I18N.t('您绑定的是一个动态IP，是否开启相关策略？'),
      content: (
        <div style={{ fontSize: 14, color: '#262626' }}>
          <Form
            ref={ref}
            requiredMark={false}
            initialValues={{
              SwitchOnSession: !!sticky,
              disableWebrtc: true,
            }}
            onFinish={(values) => {
              const { SwitchOnSession, ...rest } = values;
              resolve({
                dynamicStrategy: SwitchOnSession ? 'SwitchOnSession' : 'Off',
                ...rest,
              });
              dialog.destroy();
            }}
          >
            <DMFormItemContext.Provider value={{ disableLabelMuted: true, labelWidth: 120 }}>
              <DMFormItem
                tooltip={
                  <>
                    <>
                      {I18N.t(
                        '会话保持是指：在一个新的浏览器会话创建时，获取一个动态IP的出口IP值，在此浏览器会话期间内，一直保持这个出口IP值不变',
                      )}
                      <Typography.Link
                        style={{ color: 'inherit' }}
                        underline
                        onClick={() => {
                          openByDefaultBrowser(`${constants.productSite}/help/ip/dynamic#hold`);
                        }}
                      >
                        {I18N.t('了解更多')}
                      </Typography.Link>
                    </>
                  </>
                }
                label={I18N.t('会话保持')}
                valuePropName={'checked'}
                name={sticky ? 'SwitchOnSession' : undefined}
              >
                {!sticky ? (
                  <Space align={'center'}>
                    <Switch
                      checked={false}
                      disabled
                      unCheckedChildren={I18N.t('关闭')}
                      checkedChildren={I18N.t('启用')}
                    />
                    <Typography.Text type={'secondary'}>
                      {I18N.t('该动态IP不支持会话保持')}
                    </Typography.Text>
                  </Space>
                ) : (
                  <DmSwitch unCheckedChildren={I18N.t('关闭')} checkedChildren={I18N.t('启用')} />
                )}
              </DMFormItem>
              <DMFormItem
                label={I18N.t('Web RTC')}
                name={'disableWebrtc'}
                initialValue={true}
                tooltip={I18N.t(
                  'WebRTC 是分身所绑定的浏览器指纹的一个属性，考虑到动态IP经常变化出口IP，建议关闭 WebRTC',
                )}
              >
                <Radio.Group>
                  <Space size={32}>
                    <Radio value={true}>{I18N.t('禁用')}</Radio>
                    <Radio value={false}>{I18N.t('忽略')}</Radio>
                  </Space>
                </Radio.Group>
              </DMFormItem>
              <DMFormItem
                label={I18N.t('经纬度')}
                name={'disableLongLatitude'}
                initialValue={true}
                tooltip={I18N.t(
                  '经纬度是分身所绑定的浏览器指纹的一个属性，考虑到动态IP经常变化出口IP，建议关闭经纬度',
                )}
              >
                <Radio.Group>
                  <Space size={32}>
                    <Radio value={true}>{I18N.t('禁用')}</Radio>
                    <Radio value={false}>{I18N.t('忽略')}</Radio>
                  </Space>
                </Radio.Group>
              </DMFormItem>
            </DMFormItemContext.Provider>
          </Form>
        </div>
      ),
      footerBtns: [
        {
          key: 'yes',
          content: (
            <Button
              type={'primary'}
              onClick={() => {
                ref.current?.submit?.();
              }}
            >
              {I18N.t('确定')}
            </Button>
          ),
        },
        {
          key: 'no',
          content: (
            <Button
              onClick={() => {
                resolve({});
                dialog.destroy();
              }}
            >
              {I18N.t('取消')}
            </Button>
          ),
        },
      ],
    });
  });
}
export function createSlot(noEmitOnDestroy: boolean = false) {
  const emitter = new EventEmitter();
  const eventName = Symbol('UPDATE');
  return {
    On: () => {
      const [node, setNode] = useState<ReactNode>(null);
      useEffect(() => {
        emitter.on(eventName, setNode);
        return () => {
          emitter.off(eventName, setNode);
        };
      }, []);
      return node as JSX.Element;
    },
    Emit: (props: { children: any }) => {
      useEffect(() => {
        emitter.emit(eventName, props?.children || null);
        return () => {
          if (noEmitOnDestroy) {
            return;
          }
          emitter.emit(eventName, null);
        };
      }, [props?.children]);
      return null;
    },
    clear() {
      emitter.emit(eventName, null);
    },
  };
}

/**
 * 检查是不是在APP下
 */
export async function checkAppEnv() {
  if (!isElectron()) {
    DMConfirm({
      icon: <IconFontIcon iconName="info_241" className="anticon" />,
      title: I18N.t('无法在普通浏览器下访问分身'),
      content: (
        <span>
          {I18N.t(
            '请下载花漾客户端程序，花漾客户端内置花漾浏览器，只有通过花漾客户端才能够打开浏览器',
          )}
          <a href={getProductSiteLink(Constants.appDownloadLink)} target="_blank">
            {I18N.t('立即下载')}
          </a>
        </span>
      ),

      footerBtns: [
        {
          key: 'cancel',
          label: I18N.t('关闭'),
          btnProps: {
            type: 'primary',
          },
        },
      ],
    });
    return false;
  }
  const appPlatformCode: string = await new Promise((resolve) => {
    sendAsync('get-app-platform').then((code) => resolve(code));
    setTimeout(() => resolve(''), 100);
  });
  const { data: versionConfigsData = {} } = await metaAppVersionConfigsGet();
  const { versionConfigs } = versionConfigsData;
  if (versionConfigs) {
    const config = getVersionConfig(versionConfigs, appPlatformCode);
    if (config) {
      const appBuildNumber = getAppBuildNumber();
      if (Number(appBuildNumber) < Number(config.minBuildNo)) {
        DMConfirm({
          icon: <IconFontIcon iconName="jinggao_24" className="anticon warn" />,
          title: I18N.t('当前客户端版本过低'),
          content: I18N.t('您需要下载最新的版本后才能够打开花漾灵动'),
          okText: I18N.t('立即下载'),
          onOk: () => {
            openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp);
          },
        });
        return false;
      }
    }
  }
  return true;
}
