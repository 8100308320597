// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 刷新Channel会话Token GET /api/shop/channelSession/${param0}/token */
export async function shopChannelSessionByChannelSessionIdTokenGet(
  params: {
    // path
    /** channelSessionId */
    channelSessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { channelSessionId: param0, ...queryParams } = params;
  return request<API.WebResultShopChannelTokenVo>(`/api/shop/channelSession/${param0}/token`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 刷新Channel会话Token GET /api/shop/channelSession/${param0}/tokenV2 */
export async function shopChannelSessionByChannelSessionIdTokenV2Get(
  params: {
    // path
    /** channelSessionId */
    channelSessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { channelSessionId: param0, ...queryParams } = params;
  return request<API.WebResultShopChannelTokenVo>(`/api/shop/channelSession/${param0}/tokenV2`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 检查当前设备是否可以打开会话 GET /api/shop/checkSessionDevice */
export async function shopCheckSessionDeviceGet(
  params: {
    // query
    /** shopId */
    shopId?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/checkSessionDevice', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 关闭会话（根据分身和用户） PUT /api/shop/closeSessionByShop/${param0} */
export async function shopCloseSessionByShopByShopIdPut(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultint>(`/api/shop/closeSessionByShop/${param0}`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 发送webrtc信令 PUT /api/shop/monitor/signaling */
export async function shopMonitorSignalingPut(
  body: API.ShopSessionMonitorSignalingRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/monitor/signaling', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取信令服务器地址 GET /api/shop/session/getSignalingUrl */
export async function shopSessionGetSignalingUrlGet(options?: { [key: string]: any }) {
  return request<API.WebResultstring>('/api/shop/session/getSignalingUrl', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 关闭一个会话监控 PUT /api/shop/session/monitor/${param0}/closed */
export async function shopSessionMonitorByMonitorIdClosedPut(
  params: {
    // query
    /** traffic */
    traffic?: number;
    // path
    /** monitorId */
    monitorId: number;
  },
  options?: { [key: string]: any },
) {
  const { monitorId: param0, ...queryParams } = params;
  return request<API.WebResultShopSessionMonitorVo>(`/api/shop/session/monitor/${param0}/closed`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 会话监控心跳 PUT /api/shop/session/monitor/${param0}/heartbeatMonitor */
export async function shopSessionMonitorByMonitorIdHeartbeatMonitorPut(
  params: {
    // query
    /** traffic */
    traffic?: number;
    // path
    /** monitorId */
    monitorId: number;
  },
  options?: { [key: string]: any },
) {
  const { monitorId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/session/monitor/${param0}/heartbeatMonitor`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取会话网络配置 GET /api/shop/session/networkConfig */
export async function shopSessionNetworkConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultSessionNetworkConfig>('/api/shop/session/networkConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 关闭会话 PUT /api/shop/session/${param0}/closed */
export async function shopSessionBySessionIdClosedPut(
  params: {
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/session/${param0}/closed`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取一个会话当前监控 GET /api/shop/session/${param0}/currentMonitor */
export async function shopSessionBySessionIdCurrentMonitorGet(
  params: {
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResultShopSessionMonitorVo>(`/api/shop/session/${param0}/currentMonitor`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取会话详情 GET /api/shop/session/${param0}/detail */
export async function shopSessionBySessionIdDetailGet(
  params: {
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResultOperateLogsVo>(`/api/shop/session/${param0}/detail`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查询正在监视某个分身会话的用户 GET /api/shop/session/${param0}/findWatchingUsers */
export async function shopSessionBySessionIdFindWatchingUsersGet(
  params: {
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResultListWatchingUserVo>(`/api/shop/session/${param0}/findWatchingUsers`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 会话心跳 GET /api/shop/session/${param0}/heartbeat */
export async function shopSessionBySessionIdHeartbeatGet(
  params: {
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResultstring>(`/api/shop/session/${param0}/heartbeat`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 创建一个会话监控 POST /api/shop/session/${param0}/monitor */
export async function shopSessionBySessionIdMonitorPost(
  params: {
    // query
    /** roomName */
    roomName: string;
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResultShopSessionMonitorVo>(`/api/shop/session/${param0}/monitor`, {
    method: 'POST',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 打开会话后回调 如果success=false，会关闭会话 PUT /api/shop/session/${param0}/onOpen */
export async function shopSessionBySessionIdOnOpenPut(
  params: {
    // path
    /** sessionId */
    sessionId: number;
  },
  body: API.OnSessionOpenRequest,
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/session/${param0}/onOpen`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 更新主IP远程代理监听端口 PUT /api/shop/session/${param0}/remoteProxyPort */
export async function shopSessionBySessionIdRemoteProxyPortPut(
  params: {
    // query
    /** remoteProxyPort */
    remoteProxyPort: number;
    /** remoteProxyType */
    remoteProxyType: string;
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/session/${param0}/remoteProxyPort`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取分身会话的截屏 GET /api/shop/session/${param0}/snapshot */
export async function shopSessionBySessionIdSnapshotGet(
  params: {
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResultstring>(`/api/shop/session/${param0}/snapshot`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取分身会话的截屏成功后的回调 PUT /api/shop/session/${param0}/snapshot_ok */
export async function shopSessionBySessionIdSnapshot_okPut(
  params: {
    // query
    /** error */
    error?: string;
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/session/${param0}/snapshot_ok`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 会话切换接入点 GET /api/shop/session/${param0}/switchTransit */
export async function shopSessionBySessionIdSwitchTransitGet(
  params: {
    // query
    /** 切换某个通道的接入点，不设置时，切换所有通道 */
    channelId?: number;
    /** transitId */
    transitId: number;
    // path
    /** sessionId */
    sessionId: number;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/session/${param0}/switchTransit`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 打开分身会话 POST /api/shop/${param0}/session */
export async function shopByShopIdSessionPost(
  params: {
    // query
    /** rpaTaskId */
    rpaTaskId?: number;
    /** rpaFlowId */
    rpaFlowId?: number;
    /** openapiTaskId */
    openapiTaskId?: number;
    /** ghost */
    ghost?: boolean;
    // path
    /** shopId */
    shopId: number;
  },
  body: API.OpenSessionRequestV1,
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultShopTokenVo>(`/api/shop/${param0}/session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...queryParams,
    },
    data: body,
    ...(options || {}),
  });
}

/** 打开分身会话（预检） POST /api/shop/${param0}/sessionDryrun */
export async function shopByShopIdSessionDryrunPost(
  params: {
    // query
    /** rpaTaskId */
    rpaTaskId?: number;
    /** rpaFlowId */
    rpaFlowId?: number;
    /** openapiTaskId */
    openapiTaskId?: number;
    // path
    /** shopId */
    shopId: number;
  },
  body: API.OpenSessionRequestV1,
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/${param0}/sessionDryrun`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...queryParams,
    },
    data: body,
    ...(options || {}),
  });
}

/** 打开分身会话 POST /api/shop/${param0}/sessionV2 */
export async function shopByShopIdSessionV2Post(
  params: {
    // query
    /** rpaTaskId */
    rpaTaskId?: number;
    /** rpaFlowId */
    rpaFlowId?: number;
    /** openapiTaskId */
    openapiTaskId?: number;
    /** ghost */
    ghost?: boolean;
    // path
    /** shopId */
    shopId: number;
  },
  body: API.OpenSessionRequestV1,
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultShopTokenVo>(`/api/shop/${param0}/sessionV2`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...queryParams,
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取打开的会话列表 GET /api/shop/${param0}/sessions/actively */
export async function shopByShopIdSessionsActivelyGet(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListActiveShopSessionVo>(`/api/shop/${param0}/sessions/actively`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
