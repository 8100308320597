import I18N from '@/i18n';
import type { CSSProperties } from 'react';
import React from 'react';
import { Result, Button, message } from 'antd';
import buttonStyles from '@/style/button.less';
import { forgetMyTeam, getTeamIdFromUrl, t } from '@/utils/utils';
import IconFontIcon from '@/components/Common/IconFontIcon';
import useCurrentUser from '@/hooks/useCurrentUser';
import { isElectron, sendAsync } from '@/utils/ElectronUtils';

interface ErrorBlockProps {
  error: Error | string;
  style?: CSSProperties;
  extra?: any;
  subTitle?: any;
}

const ErrorBlock: React.FC<ErrorBlockProps> = (props) => {
  const {
    error,
    style,
    extra,
    subTitle = I18N.t('请确认您的输入是否正确。如有疑问请联系客服'),
  } = props;
  const { user } = useCurrentUser();
  const actions = [
    <Button type="primary" key="console" onClick={() => window.location.reload()}>
      {I18N.t('重新加载')}
    </Button>,
  ];
  if (isElectron()) {
    actions.push(
      <Button
        type="primary"
        ghost
        key="clearCache"
        onClick={() => {
          sendAsync('clear-app-cache').then(() => {
            message.success(I18N.t('清理缓存成功'));
          });
        }}
      >
        {I18N.t('清理缓存')}
      </Button>,
    );
  }

  let msg = I18N.t('出错了');
  try {
    if (typeof error === 'string') {
      msg = error;
    } else if (error.message) {
      msg = error.message;
    }

    let teamId: number;
    try {
      teamId = getTeamIdFromUrl();
    } catch (e) {
      console.debug(e);
    }
    if (user) {
      actions.unshift(
        <Button
          key="selectTeam"
          className={buttonStyles.successBtn}
          onClick={() => {
            forgetMyTeam(user.id);
            window.location.href = `/selectTeam`;
          }}
        >
          {I18N.t('重新选择团队')}
        </Button>,
      );
    }

    if (teamId) {
      actions.unshift(
        <Button
          key="shopList"
          onClick={() => {
            window.location.href = `/team/${teamId}/shopManage/`;
          }}
        >
          {I18N.t('回到分身列表')}
        </Button>,
      );
    }
  } catch (e) {
    console.log(e);
  }
  return (
    <Result
      icon={<IconFontIcon iconName="jinzhi_24" size={64} style={{ color: 'red' }} />}
      title={msg}
      subTitle={subTitle}
      extra={extra || actions}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...style,
      }}
    />
  );
};

export default ErrorBlock;
