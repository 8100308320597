import I18N from '@/i18n';
import { history } from 'umi';
import _ from 'lodash';
import { emitEvent, isElectron, sendAsync, sendSync } from '@/utils/ElectronUtils';
import constants from '@/constants';
import FileSaver from 'file-saver';
import prettyMilliseconds from 'pretty-ms';
import uaParser from 'ua-parser-js';
import { accountCurrentRoleGet } from '@/services/api-Account/AccountController';

const ua = uaParser(navigator.userAgent);
/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);
const { IS_PRODUCTION } = process.env;
const isProduction = IS_PRODUCTION === 'true';

export const isAntDesignPro = (): boolean => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }
  return window.location.hostname === 'preview.pro.ant.design';
};

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
export const isAntDesignProOrDev = (): boolean => {
  const { NODE_ENV } = process.env;
  if (NODE_ENV === 'development') {
    return true;
  }
  return isAntDesignPro();
};

// 输出需要做国际化的字符串
export const t = (message: string): string => {
  return message;
};

// 生成UUID
let counter = 0;
export const generateUUID = () => {
  let guid = (+new Date()).toString(32);
  let i = 0;

  for (; i < 5; i += 1) {
    guid += Math.floor(Math.random() * 65535).toString(32);
  }
  const rs = `xx_${guid}${counter.toString(32)}`;
  counter += 1;
  return rs;
};

export const getTeamNameFirstChar = (teamName: string) => {
  if (!teamName) return '';
  const chars = teamName.match(/[\u4e00-\u9fa5a-zA-Z0-9]/) || teamName;
  return chars[0].toUpperCase();
};

export const getTeamColorById = (teamId: number) => {
  // colors的数量不能为8的倍数，太容易重复
  const colors = [
    '#FF4D4F',
    '#FF7A45',
    '#FFA940',
    '#FFC53D',
    '#73D13D',
    '#36CFB7',
    '#40A9FF',
    '#598FB7',
    '#9364FE',
  ];

  return colors[teamId % colors.length];
};

export const getTeamIdFromUrl = () => {
  const { pathname } = window.location;
  const rs = pathname.match(/^\/team\/(\d+)/);
  if (rs && rs.length === 2) {
    return parseInt(rs[1], 10);
  }
  throw new Error(I18N.t('未能解析teamId'));
};

export const getUrlOfCurrentTeam = (path: string, teamId: number) => {
  return path.replace(':teamId', teamId?.toString());
};
export function getTeamEntryUrl(teamId: number) {
  return new Promise<string>((resolve) => {
    accountCurrentRoleGet({
      ...setTeamIdHeader(teamId),
      ...SkipErrorNotifyOption,
    })
      .then((res) => {
        if (res.data?.code !== 'boss' && res.data?.code !== 'superadmin') {
          resolve(`/team/${teamId}/shopManage/all`);
        } else {
          resolve(`/team/${teamId}/dashboard`);
        }
      })
      .catch((e) => {
        resolve(`/team/${teamId}/shopManage/all`);
      });
  });
}

let jwt = '';
export const getJwt = () => {
  if (isElectron()) {
    jwt = jwt || sendSync('get-jwt', {});
  } else {
    jwt = jwt || localStorage.getItem('jwt') || '';
  }
  return jwt;
};
export const setJwt = (newJwt: string) => {
  jwt = newJwt;

  // jwt失效后清除 localStorage
  if (isElectron()) {
    // 本地缓存登录态
    emitEvent('jwt-update', { jwt });
  } else if (!jwt) {
    localStorage.removeItem('jwt');
  } else {
    localStorage.setItem('jwt', jwt);
  }
};

// 将后台返回的错误消息代码转换成正常的文本
export const errorMessageI18n = (errorMessage: string) => {
  if (constants.errorMessages[errorMessage]) {
    return constants.errorMessages[errorMessage];
  }
  return errorMessage;
};

export const dateFormat = (date: Date, format: string = 'yyyy-MM-dd hh:mm:ss') => {
  let string = format;
  const o = {
    'M+': date.getMonth() + 1, // month
    'd+': date.getDate(), // day
    'h+': date.getHours(), // hour
    'm+': date.getMinutes(), // minute
    's+': date.getSeconds(), // second
    'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
    S: date.getMilliseconds(), // millisecond
  };
  if (/(y+)/.test(string)) {
    string = string.replace(RegExp.$1, `${date.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  Object.keys(o).forEach((key) => {
    if (new RegExp(`(${key})`).test(string)) {
      string = string.replaceAll(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[key] : `00${o[key]}`.substr(`${o[key]}`.length),
      );
    }
  });
  return string;
};

/**
 * 秒转换为时间
 * 结果如：5小时59分
 *
 * @param second 时间的差值，单位为秒
 * @param accuracy "minute" or "second"
 * @returns
 */
export const second2Date = (second: number, accuracy: 'minute' | 'second' = 'minute') => {
  let time = '';
  let seconds = 0;
  let minutes = 0;
  let hours = 0;
  let days = 0;
  if (second >= 60 * 60 * 24) {
    days = Math.floor(second / 86400);
    hours = Math.floor((second % 86400) / 3600);
    minutes = Math.floor(((second % 86400) % 3600) / 60);
    seconds = Math.ceil(((second % 86400) % 3600) % 60);
  } else if (second >= 60 * 60 && second < 60 * 60 * 24) {
    hours = Math.floor((second % 86400) / 3600);
    minutes = Math.floor(((second % 86400) % 3600) / 60);
    seconds = Math.ceil(((second % 86400) % 3600) % 60);
  } else if (second >= 60 && second < 60 * 60) {
    minutes = Math.floor(((second % 86400) % 3600) / 60);
    seconds = Math.ceil(((second % 86400) % 3600) % 60);
  } else {
    seconds = Math.ceil(((second % 86400) % 3600) % 60);
  }
  if (days) {
    time = `${days}${I18N.t('天')}`;
  }
  if (hours) {
    time += `${hours}${I18N.t('小时')}`;
  }
  if (minutes) {
    time += `${minutes}${I18N.t('分')}`;
  }
  if (accuracy === 'second') {
    if (seconds) {
      time += `${seconds}${I18N.t('秒')}`;
    } else if (!days && !hours && !minutes) {
      time = `${seconds}${I18N.t('秒')}`;
    }
  }
  return time;
};

export const resolveUrl = (base: string, path: string) => {
  try {
    return new URL(path, base).href;
  } catch (e) {
    return `${base}${path}`;
  }
};

/**
 * 获取门户链接
 * @param path
 */
export const getPortalUrl = (path = '') => {
  if (isElectron()) {
    const url = sendSync('get-portal-url', {});
    return resolveUrl(url, path);
  }
  if (process.env.PORTAL_URL) {
    return resolveUrl(process.env.PORTAL_URL, path);
  }
  return resolveUrl(window.location.origin, path);
};

let apiUrl = '';
/**
 * 获取API链接
 * @param path
 */
export const getApiUrl = (path = '') => {
  if (isElectron()) {
    if (!apiUrl) {
      apiUrl = sendSync('get-api-url', {});
    }
    return resolveUrl(apiUrl, path);
  }
  if (process.env.API_URL) {
    return resolveUrl(process.env.API_URL, path);
  }
  return resolveUrl(window.location.origin, path);
};

let downloadUrl = '';
const fallbackDownloadUrl = isProduction ? 'https://dl.szdamai.com' : 'https://dev.thinkoncloud.cn';
(async () => {
  if (isProduction && isElectron()) {
    try {
      downloadUrl = await sendAsync('get-download-url', {});
    } catch (e) {
      downloadUrl = fallbackDownloadUrl;
      console.error(e);
    }
  } else {
    downloadUrl = fallbackDownloadUrl;
  }
})();
export const getDownloadUrl = (path = '') => {
  return resolveUrl(downloadUrl, path);
};

export const getDownloadLink = (link: string) => {
  const downloadSite = process.env.DOWNLOAD_SITE || window.location.origin;
  return `${downloadSite}${link}`;
};
// 获取产品官网链接
export const getProductSiteLink = (path: string = '/') => {
  return constants.productSite + path;
};
export const forgetMyTeam = (userId?: number) => {
  if (isElectron()) {
    sendSync('remove-default-team', { userId });
    return;
  }
  if (userId) {
    localStorage.removeItem(`${userId}-rememberMyTeam`);
  }
};
export const rememberMyTeam = (userId: number | undefined, teamId: number | undefined) => {
  if (userId && teamId) {
    if (isElectron()) {
      sendSync('set-default-team', { userId, teamId });
      return;
    }
    localStorage.setItem(`${userId}-rememberMyTeam`, String(teamId));
  }
};
export const getMyRememberedTeam = (userId: number | undefined) => {
  if (userId) {
    if (isElectron()) {
      return sendSync('get-default-team', { userId });
    }
    return localStorage.getItem(`${userId}-rememberMyTeam`);
  }
  return false;
};

export const stringEllipsis = (string = '', maxLength: number = 20) => {
  if (string.length <= maxLength) {
    return string;
  }

  return `${string.substr(0, maxLength - 3)}...`;
};
/**
 * 设置默认页码
 * @param pageId
 * @param size
 */
export const storePageSize = (pageId: string | number, size: number) => {
  if (pageId && size) {
    localStorage.setItem(`${pageId}-page-size`, String(size));
  }
};
/**
 * 获取默认页码
 * @param pageId
 * @param defaultSize
 */
export const getStoredPageSize = (pageId: any, defaultSize?: number) => {
  if (pageId) {
    const pagesize = localStorage.getItem(`${pageId}-page-size`);
    if (pagesize) {
      return Number(pagesize);
    }
  }
  return defaultSize || 15;
};

/**
 * 通过国家名称获取 code
 * @param countryName
 */
export const getCountryCodeByName = (countryName?: string) => {
  let res = 'China';
  if (!countryName) return res;
  _.forEach(constants.Area, (name, code) => {
    if (name.indexOf(countryName) !== -1) {
      res = code;
      return false;
    }
    return true;
  });
  return res;
};

/**
 * 首字母转大写
 * @param string
 */
export const firstLetterToUpcase = (string: string) => {
  return string.slice(0, 1).toUpperCase() + string.slice(1);
};

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

export const isPublicPage = () => {
  const { location } = history;
  return _.find(constants.publicPath, (o) => {
    return location.pathname.startsWith(o);
  });
};

/**
 * 输出下载文件
 * @param fileName
 * @param content
 */
export const outputDownloadFile = (fileName: string, content: string) => {
  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
  FileSaver.saveAs(blob, fileName);
};

export const SkipErrorNotifyOption = {
  errorHandler(err) {
    console.log(err);
    throw err;
  },
};
export const setTeamIdHeader = (teamId?: number) => {
  if (teamId) {
    return { headers: { 'x-dm-team-id': teamId } };
  }
  return {};
};
/**
 * 把对象的值的首末空格去掉
 * @param map
 * @param exclude
 */
export const trimValues = (map: Record<string, any>, exclude: string[] = ['password']) => {
  const obj: Record<string, any> = {};
  Object.keys(map).forEach((key: string) => {
    const value = map[key];
    if (!exclude.includes(key) && _.isString(value)) {
      obj[key] = _.trim(value);
    } else {
      obj[key] = value;
    }
  });
  return obj;
};
export function formatMilliseconds(
  input: number,
  options?: Omit<prettyMilliseconds.Options, 'colonNotation' | 'verbose'>,
) {
  let string = prettyMilliseconds(input, { secondsDecimalDigits: 0, ...options });

  string = string.replace(/ms(?=\s|$)/, I18N.t('毫秒'));
  string = string.replace(/m(?=\s|$)/, I18N.t('分钟'));
  string = string.replace(/s(?=\s|$)/, I18N.t('秒'));
  string = string.replace(/d(?=\s|$)/, I18N.t('天'));
  string = string.replace(/y(?=\s|$)/, I18N.t('年'));
  string = string.replace(/h(?=\s|$)/, I18N.t('小时'));
  return string;
}

export function downloadContent(content: string, fileName: string) {
  const blob = new Blob([content], {
    type: 'text/plain;charset=utf-8',
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
}

/**
 * 判断一个字符串是否是纯数字组成
 * 不判空
 * @param val
 */
export function isNumberic(val: string) {
  if (val && _.trim(val)) {
    const value = _.trim(val);
    if (!/^[0-9]+$/g.test(value)) {
      // 纯数字组成
      return Promise.reject(new Error(I18N.t('应该是由纯数字组成')));
    }
  }
  return Promise.resolve();
}

export function isMacPlatform() {
  return ua.os.name?.includes('Mac');
}
export function isLinuxPlatform() {
  return navigator.platform.toLowerCase().indexOf('linux') !== -1;
}
export async function isArmArch() {
  if (navigator.userAgentData) {
    const res = await navigator.userAgentData.getHighEntropyValues(['architecture']);
    return (res.architecture || '').includes('arm');
  }
  return (ua.cpu.architecture || '').includes('arm');
}

export function isWinPlatform() {
  return ua.os.name?.includes('Windows');
}

export function isWin7Platform() {
  return ua.os.name?.includes('Windows') && Number(ua.os.version) < 10;
}

export function isAndroidPlatform() {
  return ua.os.name?.includes('Android');
}

export async function getMyPlatform() {
  let myPlatform = 'windows10';
  if (isWin7Platform()) {
    myPlatform = 'windows7';
  } else if (isMacPlatform()) {
    myPlatform = 'macos';
    myPlatform += (await isArmArch()) ? '_x64' : '_arm64';
  } else if (isLinuxPlatform()) {
    myPlatform = 'linux';
  }
  return myPlatform;
}

export function getPathSep() {
  if (!isWinPlatform()) {
    return '/';
  }
  return '\\';
}

export function getClientPlatform() {
  if (isAndroidPlatform()) {
    return 'Android';
  }
  if (isMacPlatform()) {
    return 'Mac';
  }
  if (isLinuxPlatform()) {
    return 'Linux';
  }
  return 'Windows';
}

/**
 * 根据主版本号和构建号拼接成一个版本号
 * @param mainVersion
 * @param buildVersion
 */
export function buildAppVersion(mainVersion: string = '', buildVersion?: string | number) {
  const [major, minor] = mainVersion.split('.');
  return `${major}.${minor}.${buildVersion || '0'}`;
}
export function removeHttpPrefix(url?: string) {
  return url?.replace(/^https?:\/\//, '');
}
